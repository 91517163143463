// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start();
require("channels");


import '../scss/application.scss';

// Hack to get collapse working
document.addEventListener("turbolinks:load", function() {
   document.querySelectorAll('[data-toggle=collapse]').forEach((el) => {
      el.addEventListener("click", (el) => {
         if(el.target.dataset.target) {
            const target = document.querySelector(el.target.dataset.target);

            if(target) {
               target.classList.toggle('show');
            }
         }
      }, false);
   });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
